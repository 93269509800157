import React from "react";
import FormSection from "../../FormComponents/FormSection";
import FormInput from "../../FormComponents/FormInput";

const SupplierInfoSection = ({
  supplierNip,
  supplierPhone,
  supplierEmail,
  handleChange,
  errors,
}) => {
  return (
    <FormSection title="Dane dostawcy">
      <FormInput
        handleChange={handleChange}
        value={supplierNip}
        name= "supplierNip"
        title="NIP dostawcy"
        error={errors.supplierNip}
      />
      <FormInput
        handleChange={handleChange}
        value={supplierEmail}
        name="supplierEmail"
        title="E-mail dostawcy"
        error={errors.supplierEmail}
      />
      <FormInput
        handleChange={handleChange}
        value={supplierPhone}
        name="supplierPhone"
        title="Telefon dostawcy"
        error={errors.supplierPhone}
      />
    </FormSection>
  );
};

export default SupplierInfoSection;
