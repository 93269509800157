import React from "react";
import FormSection from "../../FormComponents/FormSection";
import FormInput from "../../FormComponents/FormInput";
import FormSelect from "../../FormComponents/FormSelect";

const companyLegalFormOptions = [
    { id: 1, value: "JDG", text: "Działalność Gospodarcza" },
    { id: 2, value: "ROL", text: "Rolnik" },
    { id: 3, value: "SJA", text: "Spółka Jawna" },
    { id: 4, value: "SCA", text: "Spółka Cywilna" },
    { id: 5, value: "SAA", text: "Spółka Akcyjna" },
    { id: 6, value: "SKA", text: "Spółka Komandytowa" },
    { id: 7, value: "SZO", text: "Spółka z o.o." },
    { id: 8, value: "INN", text: "Inna" },
];
const companyAccountingOptions = [
    { id: 1, value: "RYC", text: "Ryczałt" },
    { id: 2, value: "KPR", text: "KPiR" },
    { id: 3, value: "KAP", text: "Karta podatkowa" },
    { id: 4, value: "PKS", text: "Pełna księgowość" },
];

const CompanyInfoSection = ({
  handleChange,
  nip,
  companyName,
  companyLegalForm,
  companyAccounting,
  companyWorkersNumber,
  companyIncome3Months,
  errors,
  nipFieldDisabled,
}) => {
  return (
    <FormSection title="Dane firmy">
      <FormInput
        value={nip}
        handleChange={handleChange}
        name="nip"
        title="NIP twojej firmy"
        error={errors.nip}
        disabled={nipFieldDisabled}
      />
      <FormInput
        value={companyName}
        handleChange={handleChange}
        name="companyName"
        title="Nazwa firmy"
        error={errors.companyName}
      />
      <FormSelect
        handleChange={handleChange}
        value={companyLegalForm}
        name="companyLegalForm"
        title="Rodzaj działalności"
        options={companyLegalFormOptions}
        error={errors.companyLegalForm}
      />
      <FormSelect
        handleChange={handleChange}
        value={companyAccounting}
        name="companyAccounting"
        title="Księgowość"
        options={companyAccountingOptions}
        error={errors.companyAccounting}
      />
      <FormInput
        handleChange={handleChange}
        value={companyIncome3Months}
        name="companyIncome3Months"
        title="Średni przychód z 3m"
        error={errors.companyIncome3Months}
        type="number"
      />
      <FormInput
        value={companyWorkersNumber}
        name="companyWorkersNumber"
        handleChange={handleChange}
        title="Liczba pracowników"
        error={errors.companyWorkersNumber}
        type="number"
      />
    </FormSection>
  );
};

export default CompanyInfoSection;
