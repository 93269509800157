import React from "react";
import ApplicationForm from "../components/ApplicationForm";
import App from "../components/App";

const ApplicationPage = () => {
  return (
    <App>
      <ApplicationForm />
    </App>
  );
};

export default ApplicationPage;
