import React from "react";
import FormSection from "../../FormComponents/FormSection";
import FormSelect from "../../FormComponents/FormSelect";
import FormInput from "../../FormComponents/FormInput";

const representativeRoleOptions = [
  { id: 1, value: "Właściciel" },
  { id: 2, value: "Wspólnik" },
  { id: 3, value: "Członek Zarządu" },
  { id: 4, value: "Prezes Zarządu" },
  { id: 5, value: "Prokurent" },
];
const representativeDocTypeOptions = [
  { id: 1, value: "paszport" },
  { id: 2, value: "dowód osobisty" },
];
const representativeConditionOptions = [
  { id: 1, value: "UNM", text: "panna/kawaler" },
  { id: 2, value: "MAR", text: "w związku małżeńskim" },
  { id: 3, value: "DIV", text: "rozwiedziona/rozwiedziony" },
  { id: 4, value: "WID", text: "wdowa/wdowiec" },
];
const representativePropertySeparationOptions = [
  { id: 1, value: "true", text: "tak" },
  { id: 2, value: "false", text: "nie" },
];
const representativeCitizenshipIsPlOptions = [
  { id: 1, value: "true", text: "polskie" },
  { id: 2, value: "false", text: "inne" },
];
const representativeVoivodeshipOptions = [
  { id: 1, value: "Dolnośląskie" },
  { id: 2, value: "Kujawsko-Pomorskie" },
  { id: 3, value: "Lubelskie" },
  { id: 4, value: "Lubuskie" },
  { id: 5, value: "Łódzkie" },
  { id: 6, value: "Małopolskie" },
  { id: 7, value: "Mazowieckie" },
  { id: 8, value: "Opolskie" },
  { id: 9, value: "Podkarpackie" },
  { id: 10, value: "Podlaskie" },
  { id: 11, value: "Pomorskie" },
  { id: 12, value: "Śląskie" },
  { id: 13, value: "Świętokrzyskie" },
  { id: 14, value: "Warmińsko-Mazurskie" },
  { id: 15, value: "Wielkopolskie" },
  { id: 16, value: "Zachodniopomorskie" },
];

const RepresentativeInfoSection = ({
  handleChange,
  representativeName,
  representativeLastName,
  representativePesel,
  representativeDocType,
  representativeDocNumber,
  representativeDocGovName,
  representativeDocExpiring,
  representativeCondition,
  representativePropertySeparation,
  representativeStreet,
  representativeBuildingNumber,
  representativeApartmentNumber,
  representativePostCode,
  representativeCity,
  representativeVoivodeship,
  representativeCitizenship,
  representativeRole,
  representativeCitizenshipIsPl,
  errors,
}) => {
  return (
    <FormSection title="Twoje dane">
      <FormSelect
        handleChange={handleChange}
        value={representativeRole}
        name="representativeRole"
        title="Rola w firmie"
        options={representativeRoleOptions}
        error={errors.representativeRole}
      />
      <FormInput
        handleChange={handleChange}
        title="Numer PESEL"
        value={representativePesel}
        name="representativePesel"
        error={errors.representativePesel}
      />
      <FormInput
        handleChange={handleChange}
        title="Imię"
        value={representativeName}
        name="representativeName"
        error={errors.representativeName}
      />
      <FormInput
        handleChange={handleChange}
        title="Nazwisko"
        value={representativeLastName}
        name="representativeLastName"
        error={errors.representativeLastName}
      />
      <FormSelect
        value={representativeCondition}
        handleChange={handleChange}
        name="representativeCondition"
        title="Stan cywilny"
        options={representativeConditionOptions}
        error={errors.representativeCondition}
      />
      <FormSelect
        title="Rozdzielność"
        handleChange={handleChange}
        value={representativePropertySeparation}
        name="representativePropertySeparation"
        options={representativePropertySeparationOptions}
        error={errors.representativePropertySeparation}
      />
      <FormSelect
        value={representativeDocType}
        handleChange={handleChange}
        name="representativeDocType"
        title="Dokument tożsamości"
        options={representativeDocTypeOptions}
        error={errors.representativeDocType}
      />
      <FormInput
        handleChange={handleChange}
        title="Seria i nr"
        value={representativeDocNumber}
        name="representativeDocNumber"
        error={errors.representativeDocNumber}
      />
      <FormInput
        handleChange={handleChange}
        value={representativeDocExpiring}
        name="representativeDocExpiring"
        title="Data ważności dokumentu"
        type="date"
        error={errors.representativeDocExpiring}
        placeholder="RRRR-MM-DD"
      />
      <FormInput
        value={representativeDocGovName}
        name="representativeDocGovName"
        handleChange={handleChange}
        title="Organ wydający"
        error={errors.representativeDocGovName}
      />
      <FormSelect
        value={representativeCitizenshipIsPl}
        handleChange={handleChange}
        name="representativeCitizenshipIsPl"
        title="Obywatelstwo"
        options={representativeCitizenshipIsPlOptions}
        error={errors.representativeCitizenshipIsPl}
      />
      <FormInput
        disabled={representativeCitizenshipIsPl === "true"}
        title="Obywatelstwo inne"
        name="representativeCitizenship"
        error={errors.representativeCitizenship}
        handleChange={handleChange}
        value={representativeCitizenship}
      />
      <FormSelect
        title="Województwo"
        value={representativeVoivodeship}
        name="representativeVoivodeship"
        handleChange={handleChange}
        options={representativeVoivodeshipOptions}
        error={errors.representativeVoivodeship}
      />
      <FormInput
        value={representativePostCode}
        handleChange={handleChange}
        name="representativePostCode"
        title="Kod pocztowy"
        error={errors.representativePostCode}
      />
      <FormInput
        value={representativeStreet}
        name="representativeStreet"
        handleChange={handleChange}
        title="Ulica"
        error={errors.representativeStreet}
      />
      <FormInput
        value={representativeBuildingNumber}
        name="representativeBuildingNumber"
        handleChange={handleChange}
        title="Nr domu"
        error={errors.representativeBuildingNumber}
        width="is-one-quarter"
      />
      <FormInput
        value={representativeApartmentNumber}
        name="representativeApartmentNumber"
        handleChange={handleChange}
        title="Nr lokalu"
        width="is-one-quarter"
      />
      <FormInput
        value={representativeCity}
        handleChange={handleChange}
        name="representativeCity"
        title="Miejscowość"
        error={errors.represntativeCity}
      />
    </FormSection>
  );
};

export default RepresentativeInfoSection;
