import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CompanyInfoSection from "./CompanyInfoSection";
import SupplierInfoSection from "./SuplierInfoSection";
import RepresentativeInfoSection from "./RepresentativeInfoSection";
import { ServerError } from "../Errors";
import SubmitButton from "../FormComponents/FormSubmitButton";
import { navigate } from "@reach/router";
import { validatePolish } from "validate-polish";
import * as EmailValidator from "email-validator";
import {
  fetchCompanyInfo,
  fetchOfferRequest,
  requestApplication,
  timeoutOnFetch,
} from "../../api/api";
import { useQueryParam } from "gatsby-query-params";
import { valuesToUpperCase } from "../../utils.js";
import * as DateValidator from "validate-date";

const validate = (values) => {
  const errors = {};
  if (!values.nip) {
    errors.nip = "Nie podano numeru NIP. Podaj NIP swojej firmy.";
  } else if (!validatePolish.nip(values.nip)) {
    errors.nip =
      "Nieprawidłowy nr NIP. Proszę o podanie prawidłowego numeru NIP.";
  }

  if (!values.companyName) {
    errors.companyName = "Proszę podać nazwę firmy.";
  }

  if (!values.companyLegalForm) {
    errors.companyLegalForm = "Proszę wybrać rodzaj działalności.";
  }

  if (!values.companyAccounting) {
    errors.companyAccounting = "Proszę wybrać rodzaj prowadzonej księgowości.";
  }

  if (!values.companyIncome3Months) {
    errors.companyIncome3Months =
      "Nie podano przychodu. Proszę podać średni przychód z trzech ostatnich miesięcy.";
  }

  if (!values.companyWorkersNumber) {
    errors.companyWorkersNumber =
      "Nie podano liczby pracowników. Proszę podać liczbę pracowników.";
  }

  if (!values.representativeRole) {
    errors.representativeRole = "Nie podano roli w firmie. Proszę wybrać rolę.";
  }

  if (!values.representativeName) {
    errors.representativeName = "Proszę podać imię.";
  }

  if (!values.representativeLastName) {
    errors.representativeLastName = "Proszę podać nazwisko.";
  }

  if (!values.representativePesel) {
    errors.representativePesel = "Proszę podać numer PESEL.";
  } else if (!validatePolish.pesel(values.representativePesel)) {
    errors.representativePesel = "Błędny pesel. Proszę podać poprawny PESEL.";
  }

  if (!values.representativeDocType) {
    errors.representativeDocType = "Proszę wybrać rodzaj dokumentu tożsamości.";
  }

  if (!values.representativeDocNumber) {
    errors.representativeDocNumber =
      "Proszę podać serię i numer dokumentu tożsamości.";
  }

  if (!values.representativeDocExpiring) {
    errors.representativeDocExpiring =
      "Proszę podać datę ważności dokumentu tożsamości.";
  } else if (
    !DateValidator(values.representativeDocExpiring, "boolean", "yyyy-mm-dd")
  ) {
    errors.representativeDocExpiring =
      "Proszę podać prawidłową datę w formacie: RRRR-MM-DD";
  }

  if (!values.representativeDocGovName) {
    errors.representativeDocGovName =
      "Proszę podać kto wydał dowód tożsamości.";
  }

  if (!values.representativeCondition) {
    errors.representativeCondition =
      "Nie wybrano stanu cywilnego. Proszę wybrać stan cywilny.";
  }

  if (values.representativePropertySeparation === null) {
    errors.representativePropertySeparation =
      "Nie podano czy jest rozdzielność majątkowa. Proszę wybrać z listy.";
  }

  if (
    values.representativeCitizenshipIsPl === "false" &&
    !values.representativeCitizenship
  ) {
    errors.representativeCitizenship =
      "Nie podano obywatelstwa. Proszę wpisać swoje obywatelstwo.";
  }

  if (!values.representativePostCode) {
    errors.representativePostCode =
      "Nie podano kodu pocztowego. Proszę podać kod pocztowy.";
  }

  if (!values.representativeCity) {
    errors.representativeCity =
      "Nie podano nazwy miejscowości. Proszę podać nazwę.";
  }

  if (!values.representativeStreet) {
    errors.representativeStreet = "Nie podano nazwy ulicy. Proszę podać ulicę.";
  }
  if (!values.representativeBuildingNumber) {
    errors.representativeBuildingNumber =
      "Nie podano numeru budynku. Proszę podać numer budynku.";
  }

  if (!values.representativeVoivodeship) {
    errors.representativeVoivodeship =
      "Nie wybrano województwa. Proszę wybrać województwo z listy.";
  }

  if (!values.supplierNip) {
    errors.supplierNip = "Nie podano nipu dostawcy. Proszę podać nip.";
  } else if (
    !validatePolish.nip(values.supplierNip) ||
    !(values.supplierNip.length === 10)
  ) {
    errors.nip =
      "Nieprawidłowy nr NIP. Proszę o podanie prawidłowego numeru NIP w formacie 10 cyfr.";
  }

  if (!values.supplierEmail) {
    errors.supplierEmail =
      "Nie podano adresu e-mail. Proszę podać adres e-mail.";
  } else if (!EmailValidator.validate(values.supplierEmail)) {
    errors.supplierEmail =
      "Błędny adres e-mail. Proszę o podanie prawidłowego adresu e-mail.";
  }

  if (!values.supplierPhone) {
    errors.supplierPhone =
        "Nie podano numeru telefonu dostawcy. Proszę podać telefon dostawcy.";
  }  else if (values.supplierPhone.length < 9) {
    errors.supplierPhone = "Zbyt krótki numer telefonu.";
  }

  return errors;
};

const defaultCompanyValues = {
  regon: "369292212",
  buldingNumb: "34A",
  apartmentNumber: "",
  postCode: "50-078",
  postCity: "Wrocław",
  city: "Wrocław",
  street: "H. Sienkiewicza",
  voivodeship: "Dolnośląskie",
  krs: "0000714263",
};

const ApplicationForm = () => {
  const formik = useFormik({
    initialValues: {
      nip: "",
      companyIncome3Months: "",
      companyLegalForm: "",
      companyAccounting: "",
      companyWorkersNumber: "",
      companyName: "",
      supplierNip: "",
      supplierPhone: "",
      supplierEmail: "",
      representativeName: "",
      representativeLastName: "",
      representativePesel: "",
      representativeDocType: "",
      representativeDocNumber: "",
      representativeDocGovName: "",
      representativeDocExpiring: "",
      representativeCondition: "",
      representativePropertySeparation: "",
      representativeStreet: "",
      representativeBuildingNumber: "",
      representativeApartmentNumber: "",
      representativePostCode: "",
      representativeCity: "",
      representativeVoivodeship: "",
      representativeRole: "",
      representativeCitizenship: "",
      representativeCitizenshipIsPl: "true",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate,
    onSubmit: async (values) => {
      setIsLoading(true);
      const alternativeCompanyStreet = companyData.Miejscowosc
        ? companyData.Miejscowosc[0]
        : defaultCompanyValues.street;
      const valuesToSend = {
        ...values,
        resultId,
        companyRegon: companyData.Regon
          ? companyData.Regon[0]
          : defaultCompanyValues.regon,
        companyBuldingNumb: companyData.NrNieruchomosci
          ? companyData.NrNieruchomosci[0]
          : defaultCompanyValues.apartmentNumber,
        companyApartmentNumber: companyData.NrLokalu
          ? companyData.NrLokalu[0]
          : defaultCompanyValues.apartmentNumber,
        companyPostCode: companyData.KodPocztowy
          ? companyData.KodPocztowy[0]
          : defaultCompanyValues.postCode,
        companyPostCity: companyData.MiejscowoscPoczty
          ? companyData.MiejscowoscPoczty[0]
          : defaultCompanyValues,
        companyCity: companyData.Miejscowosc
          ? companyData.Miejscowosc[0]
          : defaultCompanyValues.city,
        companyStreet: companyData.Ulica
          ? companyData.Ulica[0]
          : alternativeCompanyStreet,
        companyVoivodeship: companyData.Wojewodztwo
          ? companyData.Wojewodztwo[0]
          : defaultCompanyValues.voivodeship,
        companyKrs: companyData.krs
          ? companyData.krs[0]
          : defaultCompanyValues.krs,
        ...requestData,
      };
      const upperCaseValues = valuesToUpperCase(valuesToSend);
      await timeoutOnFetch(requestApplication(upperCaseValues))
        .then(async (res) => {
          const data = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              setErrors([...data.errors]);
            } else {
              setServerError(true);
            }
            setIsLoading(false);
            return;
          }
          let linkToAppSent = `/appsent?id=${requestId}`;
          navigate(linkToAppSent);
        })
        .catch(() => {
          setServerError(true);
        });
    },
  });
  const [requestData, setRequestData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [nipFieldDisabled, setNipFieldDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const requestId = useQueryParam("id");
  const resultId = useQueryParam("resultid");

  useEffect(() => {
    if (!requestId) {
      return;
    }
    const sessionRequestData = JSON.parse(
      sessionStorage.getItem("requestData")
    );
    if (Object.keys({ ...sessionRequestData }).length > 0) {
      setRequestData(sessionRequestData);
      return;
    }
    timeoutOnFetch(fetchOfferRequest({ requestId }))
      .then((response) => {
        const data = response.json();
        if (!response.ok) {
          setServerError(true);
          return;
        }
        setRequestData(data);
      })
      .catch(() => {
        setServerError(true);
      });
  }, [setRequestData, requestId]);

  useEffect(() => {
    if (!Object.keys(requestData).length) {
      return;
    }
    if (requestData.nip !== null) {
      const nip = requestData.nip;
      formik.setFieldValue("nip", nip);
      setNipFieldDisabled(true);
      timeoutOnFetch(fetchCompanyInfo({ nip })).then(async (response) => {
        const data = await response.json();
        if (response.status === 400) {
          //when nip isn't in GUS db - if nip is fake, we set company data to empty object and send default values.
          return;
        }
        if (!response.ok) {
          setServerError(true);
          return;
        }
        setCompanyData(data);
      });
    } else {
      setCompanyData({});
    }
  }, [requestData, setCompanyData]);

  useEffect(() => {
    let companyName = "";
    if (companyData.Nazwa) {
      companyName = companyData.Nazwa[0];
    }
    let companyLegalForm = "";
    if (companyData.szczegolnaFormaPrawna) {
      switch (companyData.szczegolnaFormaPrawna[0]) {
        case "16":
        case "116":
          companyLegalForm = "SAA";
          break;
        case "17":
        case "117":
          companyLegalForm = "SZO";
          break;
        case "18":
        case "118":
          companyLegalForm = "SJA";
          break;
        case "19":
        case "019":
          companyLegalForm = "SCA";
          break;
        case "20":
        case "21":
        case "120":
        case "121":
          companyLegalForm = "SKA";
          break;
        case "099":
          companyLegalForm = "JDG";
          break;
        default:
          companyLegalForm = "INN";
      }
    }
    formik.setFieldValue("companyLegalForm", companyLegalForm);
    formik.setFieldValue("companyName", companyName);
  }, [companyData]);

  useEffect(() => {
    if (formik.values.representativeCitizenshipIsPl) {
      formik.setFieldValue("representativeCitizenship", "");
    }
  }, [formik.values.representativeCitizenshipIsPl, formik.setFieldValue]);

  return (
    <div>
      {serverError ? <ServerError /> : null}
      <section className="section">
        <div className="container">
          <form onSubmit={(e) => formik.handleSubmit(e)}>
            <div className="columns">
              <div className="column is-half app-column">
                <CompanyInfoSection
                  handleChange={formik.handleChange}
                  nip={formik.values.nip}
                  companyName={formik.values.companyName}
                  companyLegalForm={formik.values.companyLegalForm}
                  companyAccounting={formik.values.companyAccounting}
                  companyWorkersNumber={formik.values.companyWorkersNumber}
                  companyIncome3Months={formik.values.companyIncome3Months}
                  errors={formik.errors}
                  requestId={requestId}
                  nipFieldDisabled={nipFieldDisabled}
                />
                <SupplierInfoSection
                  handleChange={formik.handleChange}
                  supplierNip={formik.values.supplierNip}
                  supplierEmail={formik.values.supplierEmail}
                  supplierPhone={formik.values.supplierPhone}
                  errors={formik.errors}
                />
                <div className="visible-desktop">
                  <SubmitButton isLoading={isLoading} text="Wyślij wniosek" />
                </div>
              </div>
              <div className="column is-half">
                <RepresentativeInfoSection
                  handleChange={formik.handleChange}
                  representativeBuildingNumber={
                    formik.values.representativeBuildingNumber
                  }
                  representativeCity={formik.values.representativeCity}
                  representativeCondition={
                    formik.values.representativeCondition
                  }
                  representativeName={formik.values.representativeName}
                  representativePesel={formik.values.representativePesel}
                  representativeDocType={formik.values.representativeDocType}
                  representativeDocGovName={
                    formik.values.representativeDocGovName
                  }
                  representativeDocExpiring={
                    formik.values.representativeDocExpiring
                  }
                  representativePropertySeparation={
                    formik.values.representativePropertySeparation
                  }
                  representativeStreet={formik.values.representativeStreet}
                  representativePostCode={formik.values.representativePostCode}
                  representativeVoivodeship={
                    formik.values.representativeVoivodeship
                  }
                  representativeRole={formik.values.representativeRole}
                  representativeCitizenship={
                    formik.values.representativeCitizenship
                  }
                  representativeCitizenshipIsPl={
                    formik.values.representativeCitizenshipIsPl
                  }
                  representativeDocNumber={
                    formik.values.representativeDocNumber
                  }
                  representativeLastName={formik.values.representativeLastName}
                  representativeApartmentNumber={
                    formik.values.representativeApartmentNumber
                  }
                  errors={formik.errors}
                />
                <div className="visible-mobile">
                  <SubmitButton isLoading={isLoading} text="Wyślij wniosek" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ApplicationForm;
